import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import List from "../../list/Booking";
import Add from "../../form/BookingFacility";
import Edit from "../../form/BookingFacility";
import Details from "../../details/Booking/BookingDetail";
import FacilityDetails from "../../details/Booking/FacilityDetail";
import { useSelector } from "react-redux";

function Component() {
  let { path } = useRouteMatch();
  const page = "facility";
  const activeModuleAccess = useSelector((state) => state.auth.access);
  const [read, setRead] = useState(false);
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [del, setDel] = useState(false);

  useEffect(() => {
    if (
      typeof activeModuleAccess.unmapped !== "undefined" &&
      activeModuleAccess.unmapped.length > 0
    ) {
      let access = activeModuleAccess.unmapped.filter((item) => {
        return item.value == page;
      });

      if (access.length > 0) {
        access = access[0].privilege;
      }

      access.map((item) => {
        switch (item) {
          case "read":
            if (!read) {
              setRead(true);
            }
            break;
          case "create":
            if (!create) {
              setCreate(true);
            }
            break;
          case "update":
            if (!update) {
              setUpdate(true);
            }
            break;
          case "delete":
            if (!del) {
              setDel(true);
            }
            break;
        }
      });
    }
  }, [activeModuleAccess]);

  return (
    <Switch>
      {create && (
        <Route path={`${path}/add`}>
          <Add />
        </Route>
      )}
      {update && (
        <Route path={`${path}/edit`}>
          <Edit />
        </Route>
      )}
      {read && (
        <>
          <Route exact path={path}>
            <List
              view={read}
              canDelete={del}
              canAdd={create}
              canUpdate={update}
            />
          </Route>
          <Route path={`${path}/:id`}>
            <FacilityDetails
              view={read}
              canDelete={del}
              canAdd={create}
              canUpdate={update}
            />
          </Route>
        </>
      )}
    </Switch>
  );
}

export default Component;
